












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_CURRENT_IMPRINT_CONTENT } from '@/store/user/actions.type'
import { SimpleServerResponse } from '@/store/types'
@Component
export default class Dashboard extends Vue {
  @Action(GET_CURRENT_IMPRINT_CONTENT)
  public getCurrentImprintContent: (locale: string) => Promise<SimpleServerResponse>

  imprint = ''

  get correctImprint (): string {
    this.getCurrentImprintContent(this.$root.$i18n.locale).then(data => {
      this.imprint = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('imprint.' + error.message), true)
    })
    return this.imprint
  }
}

